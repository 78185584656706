@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Custom";
    src:
            local("Custom"),
            url("./assets/fonts/Louis-George-Cafe.ttf");
}
/**
primary: #064091
 */

body{
    font-family: Helvetica Neue,Helvetica,Arial,sans,serif;
}

::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-thumb {
    background: #dedede;
    width: 2px;
}

.boxShadow {
    box-shadow: 1px 1px 10px 1px #c9c9c9;
}

td:nth-child(even),th:nth-child(even) {
    background-color: rgba(234, 238, 248, 0.4);
}


.table{
    width: 100%;
    text-align: left;
}

.table2{
    width: 100%;
    text-align: left;
}

th, td {
    padding: 5px;
    text-align: left;
}

.btn-primary {
    background-color: #064091; /* Green */
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.btn-primary2 {
    background-color: #2b7ff8; /* Green */
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.button {
    background-color: #00a170; /* Green */
    border: none;
    color: white;
    padding: 6px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-right: 5px;
}

.table-input{
    padding: 5px;
    background-color: #d2d2d2;
    font-size: 14px;
}

.button-danger {
    background-color: #a10086; /* Green */
    border: none;
    color: white;
    padding: 6px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 5px;
}

.button-normal {
    background-color: #000000; /* Green */
    border: none;
    color: white;
    padding: 6px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-right: 5px;
}

.btn-danger{
    background-color: #91063b; /* Green */
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.button-warning {
    background-color: #ff6e00; /* Green */
    border: none;
    color: white;
    padding: 6px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-right: 5px;
}

.btn-warning{
    background-color: #ff6e00; /* Green */
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
